.footer {
  padding: 40px 0;
  flex-grow: 0;
  opacity: .5;

  &__inner {
    // display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 760px;
    max-width: 100%;

    @media ($tablet) {
      flex-direction: column;
    }
  }

  a {
    color: inherit;
  }

  // .copyright {
  //   display: flex;
  //   flex-flow: row wrap;
  //   flex: 1;
  //   align-items: center;
  //   font-size: 1rem;
  //   justify-content: center;

  //   &--user {
  //     margin: auto;
  //     text-align: center;
  //   }

  //   & > *:first-child:not(:only-child) {
  //     margin-right: 10px;
  //   }

    // span {
    //   white-space: nowrap;
    // }
  // }
}
