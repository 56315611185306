/* COLOR VARIABLES */
$background: mix($accent, #1D1E28, 2%);
$color: white;
$border-color: rgba(255, 255, 255, .1);

/* MEDIA QUERIES */
$phone: "max-width: 684px";
$tablet: "max-width: 900px";

/* variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}
